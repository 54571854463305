<template>
  <div class="loan_item">
    <div class="inner">
      <h3>Términos de privacidad</h3>
      <div class="left_cell">
        <div class="cell_item">
          <h2>POLÍTICA PARA LA PROTECCIÓN Y TRATAMIENTO DE DATOS PERSONALES MiRuiqueza S.A.S</h2>
        </div>
        <div class="cell_item">
          <h2>GENERALIDADES</h2>
          <p>En cumplimiento a lo dispuesto en la Ley Estatutaria 1581 de 20121 y sus Decretos Reglamentarios, la Empresa establece la Política General y Especiales aplicables para el Tratamiento y Protección de Datos Personales en la organización</p>
        </div>
        <div class="cell_item">
          <h2>IDENTIFICACIÓN DEL RESPONSABLE</h2>
          <p>MiRuiqueza S.A.S es una sociedad comercial identificada con NIT 901.557.034-1 con matrícula mercantil 03465795 y con domicilio principal en la ciudad de Bogotá D.C., que se constituye como una Empresa Mexico cuyo objeto social consiste en el desarrollo de productos y servicios que promuevan la inclusión financiera y el acceso al crédito de las personas, así como de las micro, pequeñas y medias empresas (MiPymes).</p>
          <br>
          <br>
          <br>
          <p>DIRECCIÓN FÍSICA: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; CRA 13 # 97-51</p>
          <p>CORREO ELECTRÓNICO: &nbsp;&nbsp;&nbsp; service@moaimagic.net</p>
          <p>PÁGINA WEB: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;http://dragonfly-sas.co/loan </p>
          <p>TELÉFONO: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3187171958-3142127338</p>
          <br>
          <p>OBJETIVO</p>
          <p>La presente Política establece las directrices generales para la protección y el tratamiento de datos personales al interior de la Compañía, permitiendo de esta manera fortalecer el nivel de confianza entre responsable y Titulares con relación al tratamiento de su información; Informar a los Titulares sobre las finalidades y transferencias a que son sometidos sus datos personales y los mecanismos y formas para el ejercicio de sus derechos.</p>
        </div>
        <div class="cell_item">
          <h2>ALCANCE</h2>
          <p>Esta Política de Tratamiento y Protección de Datos Personales (en adelante la “Política”) será aplicada a todas las bases de datos y/o archivos que incluyan datos personales que sean objeto de tratamiento por parte de MiRuiqueza S.A.S como responsable del tratamiento de datos personales.</p>
          <p>La presente constituye la versión 1.0 de Política de Tratamiento de Datos Personales de la Compañía, lo anterior, con base en el proceso de actualización de bases de datos que realizó la empresa dando cumplimiento a la Circular Externa 003 del 2018, esto es la actualización que debe realizar toda empresa de sus bases de datos entre el 02 de enero y el 31 de marzo de cada año a partir del 2020.</p>
        </div>
        <div class="cell_item">
          <h2>TERMINOLOGÍA</h2>
          <p><b>Autorización:</b> Consentimiento previo, expreso e informado del Titular para llevar a cabo el tratamiento de datos personales.Aviso de privacidad: Es el documento físico, electrónico o en cualquier otro formato conocido o por conocer, que es puesto a disposición del Titular con el fin de informar sobre el tratamiento de sus datos personales.</p>
          <p>Aviso de privacidad: Es el documento físico, electrónico o en cualquier otro formato conocido o por conocer, que es puesto a disposición del Titular con el fin de informar sobre el tratamiento de sus datos personales.</p>
          <p><b>Base de datos:</b> Conjunto organizado de datos personales que sea objeto de tratamiento.</p>
          <p><b>Causa habiente:</b> Persona que por sucesión o transmisión adquiere los derechos de otra persona.</p>
          <p><b>Dato personal:</b> Cualquier información vinculada o que pueda asociarse a una o varias personas naturales determinadas o determinables.</p>
          <p><b>Encargado del tratamiento:</b> Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, realice el tratamiento de datos personales por cuenta del responsable del tratamiento.</p>
          <p><b>Habeas data:</b> Derecho que tiene toda persona de conocer, actualizar y rectificar la información que se haya recogido sobre ella en archivos y bancos de datos de naturaleza pública o privada.</p>
          <p><b>Responsable del tratamiento:</b> Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, decida sobre la base de datos y/o el tratamiento de los datos.</p>
          <p><b>Tratamiento:</b> Cualquier operación o conjunto de operaciones sobre datos personales, tales como la recolección, almacenamiento, uso, circulación o supresión.</p>
          <p><b>Titular:</b> Persona natural cuyos datos personales sean objeto de tratamiento</p>
        </div>
        <div class="cell_item">
          <h2>NORMATIVIDAD APLICABLE</h2>
          <p><b>Ley 1581 de 2012</b>– Disposiciones Generales para la Protección de Datos Personales.</p>
          <p><b>Decreto 1074 de 2015</b>– Por medio del cual se expide el Decreto Único Reglamentario del Sector Comercio, Industria y Turismo. Capítulo 25: Reglamenta parcialmente la Ley 1581 de 2012.</p>
          <p><b>Ley 1273 de 2009</b> – Por medio de la cual se modifica el Código Penal, se crea un nuevo bien jurídico tutelado – denominado ‘de la protección de la información y de los datos’- y se preservan integralmente los sistemas que utilicen las tecnologías de la información y las comunicaciones, entre otras disposiciones. </p>
          <p>DOCUMENTOS RELACIONADOS 001 – Manual del usuario</p>
        </div>
        <div class="cell_item">
          <h2>TRESPONSABILIDADES</h2>
          <p><b>Titulares de los datos personales:</b>Relacione las responsabilidades principales frente al cumplimiento de la política.</p>
          <p><b>Responsable del Tratamiento de Datos Personales:</b>Relacione las responsabilidades principales frente al cumplimiento de la política.</p>
          <p><b>Oficial de Protección de Datos Personales:</b>Relacione las responsabilidades principales frente al cumplimiento de la política.</p>
        </div>
        <div class="cell_item">
          <h2>DISPOSICIONES</h2>
          <h2>PRINCIPIOS RECTORES APLICABLES EN MATERIA DE DATOS PERSONALES</h2>
          <p>En materia de protección de datos personales se deben aplicar los siguientes principios rectores:</p>
          <p><b>Principio de legalidad en materia de Tratamiento de Datos:</b>El tratamiento a que se refiere la Ley de Habeas Data es una actividad regulada que debe sujetarse a lo establecido en ella y en las demás disposiciones que la desarrollen.</p>
          <p><b>Principio de finalidad:</b>El tratamiento debe obedecer a una finalidad legítima de acuerdo con la Constitución y la ley, la cual debe ser informada al Titular.</p>
          <p><b>Principio de libertad:</b>El tratamiento sólo puede ejercerse con el consentimiento, previo, expreso e informado del Titular. Los datos personales no podrán ser obtenidos o divulgados sin previa autorización, o en ausencia de mandato legal o judicial que releve el consentimiento.</p>
          <p><b>Principio de veracidad o calidad:</b> La información sujeta a tratamiento debe ser veraz, completa, exacta, actualizada, comprobable y comprensible. Se prohíbe el tratamiento de datos parciales, incompletos, fraccionados o que induzcan a error.</p>
          <p><b>Principio de transparencia:</b>En el tratamiento debe garantizarse el derecho del Titular a obtener del responsable del tratamiento o del Encargado del tratamiento, en cualquier momento y sin restricciones, información acerca de la existencia de datos que le conciernan.</p>
          <p><b>Principio de acceso y circulación restringida: </b> El tratamiento se sujeta a los límites que se derivan de la naturaleza de los datos personales, de las disposiciones de la ley y la Constitución. En este sentido, el tratamiento sólo podrá hacerse por personas autorizadas por el Titular y/o por las personas previstas en la ley. Los datos personales, salvo la información pública, no podrán estar disponibles en Internet u otros medios de divulgación o comunicación masiva, salvo que el acceso sea técnicamente controlable para brindar un conocimiento restringido sólo a los Titulares o terceros autorizados conforme a la ley.</p>
          <p><b>Principio de seguridad: </b> La información sujeta a tratamiento por el responsable del tratamiento o Encargado del tratamiento a que se refiere la Ley de Habeas Data, se deberá manejar con las medidas técnicas, humanas y administrativas que sean necesarias para otorgar seguridad a los registros evitando su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento.</p>
          <p>Principio de confidencialidad: Todas las personas que intervengan en el tratamiento de datos personales que no tengan la naturaleza de públicos están obligadas a garantizar la reserva de la información, inclusive después de finalizada su relación con alguna de las labores que comprende el tratamiento, pudiendo sólo realizar suministro o comunicación de datos personales cuando ello corresponda al desarrollo de las actividades autorizadas en la ley y en los términos de la misma.</p>
        </div>
        <div class="cell_item">
          <h2>DERECHOS DE LOS TITULARES</h2>
          <p>Los Titulares de datos personales gozarán de los siguientes derechos, y de aquellos que les otorgue la ley:</p>
          <p>Conocer, actualizar y rectificar sus datos personales frente al responsable del tratamiento o Encargados del tratamiento. Este derecho se podrá ejercer, entre otros frente a datos parciales, inexactos, incompletos, fraccionados, que induzcan a error, o aquellos cuyo tratamiento esté expresamente prohibido o no haya sido autorizado.</p>
          <p>Solicitar prueba de la autorización otorgada al responsable del tratamiento salvo cuando expresamente se exceptúe como requisito para el tratamiento, de conformidad con lo previsto en el artículo 10 de la ley.</p>
          <p>Ser informado por el responsable del tratamiento o el Encargado del tratamiento, previa solicitud, respecto del uso que les ha dado a sus datos personales.</p>
          <p>Presentar ante la Superintendencia de Industria y Comercio quejas por infracciones a lo dispuesto en la ley y las demás normas que la modifiquen, adicionen o complementen.  Revocar la autorización y/o solicitar la supresión del dato cuando en el tratamiento no se respeten los principios, derechos y garantías constitucionales y legales. La revocatoria y/o supresión procederá cuando la Superintendencia de Industria y Comercio haya determinado que en el tratamiento el responsable o Encargado han incurrido en conductas contrarias a la ley y a la Constitución.</p>
          <p>Acceder en forma gratuita a sus datos personales que hayan sido objeto de tratamiento.</p>
        </div>
        <div class="cell_item">
          <h2>AUTORIZACIÓN DEL TITULAR DE DATOS PERSONALES</h2>
          <p>Sin perjuicio de las excepciones previstas en la Ley Estatutaria 1581 de 2012, por regla general en el tratamiento de datos de carácter personal la Compañía recolectará la autorización previa e informada del Titular, la cual podrá ser obtenida por cualquier medio que pueda ser objeto de consulta posterior.</p>
          <p>Eventos en los cuales no es necesaria la autorización Información requerida por una entidad pública o administrativa en ejercicio de sus funciones legales o por orden judicial</p>
          <p>Datos de naturaleza pública.</p>
          <p>Casos de urgencia médica o sanitaria.</p>
          <p>Tratamiento de información autorizado por la ley para fines históricos, estadísticos o científicos.Datos relacionados con el Registro Civil de las Personas.</p>
        </div>
        <div class="cell_item">
          <h2>DEBERES DE LA COMPAÑÍA DE MiRuiqueza S.A.S COMO RESPONSABLE DEL TRATAMIENTO DE DATOS PERSONALES</h2>
          <p>MiRuiqueza S.A.S como responsable del tratamiento de datos personales, cumplirá los siguientes deberes:</p>
          <p>Garantizar al Titular, en todo tiempo, el pleno y efectivo ejercicio del derecho de hábeas data.</p>
          <p>Solicitar y conservar, en las condiciones previstas en la ley, copia de la respectiva autorización otorgada por el Titular.</p>
          <p>Informar debidamente al Titular sobre la finalidad de la recolección y los derechos que le asisten por virtud de la autorización otorgada.</p>
          <p>Conservar la información bajo las condiciones de seguridad necesarias para impedir su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento.</p>
          <p>Garantizar que la información que se suministre al Encargado del tratamiento sea veraz, completa, exacta, actualizada, comprobable y comprensible.</p>
          <p>Actualizar la información, comunicando de forma oportuna al Encargado del tratamiento, todas las novedades respecto de los datos que previamente le haya suministrado y adoptar las demás medidas necesarias para que la información suministrada a este se mantenga actualizada.</p>
          <p>Rectificar la información cuando sea incorrecta y comunicar lo pertinente al encargado del tratamiento.</p>
          <p>Suministrar al Encargado del tratamiento, según el caso, únicamente datos cuyo tratamiento esté previamente autorizado de conformidad con lo previsto en la presente ley.</p>
          <p>Exigir al Encargado del tratamiento en todo momento, el respeto a las condiciones de seguridad y privacidad de la información del Titular.</p>
          <p>Tramitar las consultas y reclamos formulados en los términos señalados en la Ley Estatutaria 1581 de 2012.</p>
          <p>Adoptar un manual interno de políticas y procedimientos para garantizar el adecuado cumplimiento de la ley y en especial, para la atención de consultas y reclamos.</p>
          <p>Informar al Encargado del tratamiento cuando determinada información se encuentra en discusión por parte del Titular, una vez se haya presentado la reclamación y no haya finalizado el trámite respectivo.</p>
          <p>Informar a solicitud del Titular sobre el uso dado a sus datos</p>
          <p>Informar a la autoridad de protección de datos cuando se presenten violaciones a los códigos de seguridad y existan riesgos en la administración de la información de los Titulares.</p>
          <p>Cumplir las instrucciones y requerimientos que imparta la Superintendencia de Industria y Comercio.</p>
        </div>
        <div class="cell_item">
          <p>POLÍTICAS ESPECÍFICAS PARA EL TRATAMIENTO DE DATOS PERSONALES.</p>
          <p>Tratamiento de datos personales de Clientes</p>
          <p>La Compañía recolecta los datos personales de sus clientes y los almacena en una base de datos la cual es calificada por MiRuiqueza S.A.S como de reserva, y sólo será revelada con la expresa autorización del titular o cuando una Autoridad Competente lo solicite. Las finalidades para las cuales son utilizados los datos personales de los clientes de la Compañía, serán:</p>
          <p>Contar con documentos digitalizados y físicos de los clientes con el propósito de gestionar el proceso de vinculación y creación del cliente en la empresa.</p>
          <p>Generación de informes mensuales sobre el comportamiento de la cartera del cliente.Prestar servicios crediticios a personas naturales y jurídicas a través de plataformas electrónicas.</p>
          <p>Realizar ante los operadores de información (Experian, Transunión y/o cualquier otra entidad que llegue a manejar bases de datos con los mismos objetivos) la consulta de la información crediticia, así como el reporte de la generación, modificación, extinción, cumplimiento o incumplimiento de las obligaciones contraídas.</p>
          <p>Compartir e intercambiar con sus entidades filiales, matrices, aliadas y/o con entidades financieras, cesionarios o inversionistas, la información personal y financiera, así como la información en relación con créditos contratados u otorgados con o a través la Compañía, contenida en las bases de datos de la entidad con fines de control de riesgos, desembolso y pago de obligaciones.</p>
          <p>Realizar servicios legalmente autorizados bajo el contrato de mutuo firmado con nuestros clientes, como pueden ser cesiones de cartera, cobro de obligaciones y cualquier otra actividad autorizada en dicho contrato.</p>
          <p>Adelantar contactos con fines comerciales y/o promocionales ya sea sobre sus propios servicios y productos, o los de terceros con los que la Compañía tenga relaciones comerciales o alianzas, a través de correo, teléfono, celular, correo electrónico o cualquier otro medio conocido o por conocer.</p>
          <p>Realizar actividades de gestión de cobro, aviso de reporte a las centrales de riesgo, entrega de extractos de obligaciones y actualizar la información a través de diferentes actividades como lo son la consulta en bases de datos públicas, páginas de internet y redes sociales y referencias de terceras personas. Así mismo, por parte de MiRuiqueza S.A.S   se solicita información de terceras personas que podrá utilizar en una etapa pre-contractual, contractual o post-contractual con el fin de actualizar y llevar a cabo actividades de localización de clientes para asegurar una adecuada gestióndel crédito y experiencia del cliente, razón por la cual el cliente debe contar con la autorización del(os) tercero(s) que proporcionará como referencia(s).</p>
          <p>Seguimiento de procesos jurídicos, identificación de pagos realizados y calificación de cartera de manera directa o a través de terceros ubicados dentro y fuera del territorio nacional.</p>
          <p>Seguimiento continuo a nuestros clientes, mediante estrategias de fidelización efectivas propiciando confianza y lealtad para la prestación de nuevos servicios.</p>
          <p>Con el propósito de dar a conocer a los clientes el portafolio de servicios crediticios que son ofrecidos a través de la Empresa.</p>
          <p>Consultar y reportar información de los clientes a través de centrales de riesgo y de acuerdo con lo establecido en la Ley 1266 de 2008.</p>
          <p>Contactar al Titular a través de medios físicos y electrónicos, incluyendo pero sin limitar, los siguientes medios: correo electrónico, Short Message Servive (SMS por sus siglas en inglés), Multimedia Message Service (MMS por sus siglas en inglés), Unstructured Supplementary Service Data (USSD por sus siglas en inglés), mensajes de WhatsApp, Push Notification, y chats de redes sociales y aplicaciones asociadas al número telefónico registrado por el cliente para el envío de información de interés o relacionada con el portafolio de servicios.</p>
          <p>Mantener contacto con la sociedad aun cuando finalice la relación contractual.</p>
          <p>Compartir la información con terceros aliados que colaboran con la sociedad, considerando que para el cumplimiento de sus encargos deben acceder en alguna medida a la información, los cuales estarán igualmente sujetos a las obligaciones de confidencialidad, manejo de la información y protección de datos personales a las que está sujeto esta sociedad. tales como proveedores de servicios de mensajería, agencias de publicidad, casas de cobranzas, proveedores de productos. Etc.</p>
          <p>Dar atención y seguimiento a las solicitudes que reciba de los Titulares de Datos Personales, en ejercicio del derecho de Habeas data.</p>
          <p>Gestión administrativa para la ejecución de las etapas precontractual, contractual y post contractual.</p>
          <p>Evaluar la atención brindada al cliente y realizar actividades de prospección comercial.Gestionar y dar atención oportuna a las peticiones, quejas o reclamos presentados por los clientes de la organización a través de los canales de servicio al cliente.</p>
          <p>Dar cumplimiento al Sistema de prevención de lavado de activos y financiación del terrorismo adoptado por la empresa y en consecuencia consultar al titular en listas restrictivas.</p>
          <p>Por otro lado, el titular autoriza el envío de contenido publicitario, incluyendo pero sin limitar, los siguientes medios: correo electrónico, Short Message Servive (SMS por sus siglas en inglés), Multimedia Message Service (MMS por sus siglas en inglés), Unstructured Supplementary Service Data (USSD por sus siglas en inglés), mensajes de WhatsApp, Push Notification dentro de la franja horaria entre las 8:00 am hasta las 9:30 pm, y los días sábado, domingo y festivo hasta las 18:30 horas de manera segmentada. Adicionalmente, la información no será objeto de tratamiento por un período superior al tiempo que dure la relación contractual entre el cliente y la Compañía, y el tiempo adicional que se requiera de acuerdo con las circunstancias legales o contractuales que hagan necesario el manejo de la información.</p>
          <p>Tratamiento de datos personales de Prospectos La Compañía recolecta los datos personales de prospectos y los almacena en una base de datos la cual es calificada por MiRuiqueza S.A.S como de reserva, y solo será revelada con la expresa autorización del titular o cuando una autoridad competente lo solicite.</p>
          <p>Las finalidades para las cuales son utilizados los datos personales de los prospectos de La Compañía, serán:</p>
          <p>Dar a conocer el portafolio de servicios crediticios que son comercializados por la Compañía.</p>
          <p>Realizar seguimiento y perfilación en el proceso de adquisición de un crédito y actividades de prospección comercial.</p>
          <p>Consultar al interesado en centrales de riesgo y con base en la Ley 1266 de 2008.</p>
          <p>Mantener contacto con la Compañía a través de canales físicos y digitales Realizar invitaciones a eventos y ofrecer nuevos productos y servicios.</p>
          <p>Suministrar información de contacto a la fuerza comercial y/o red de distribución, y cualquier tercero con el cual la Compañía tenga un vínculo contractual para el desarrollo de la actividad de prospección comercial.</p>
          <p>Contactar al Titular a través de medios físicos y electrónicos, incluyendo pero sin limitar, lo siguientes medios: correo electrónico, , Short Message Servive (SMS por sus siglas en inglés), Multimedia Message Service (MMS por sus siglas en inglés),Unstructured Supplementary Service Data (USSD por sus siglas en inglés), mensajes de WhatsApp, Push Notification, y chats de redes sociales y aplicaciones asociadas al número telefónico registrado por el cliente para el envío de información de interés o relacionada con el portafolio de servicios.</p>
          <p>- Consultar antecedentes del prospecto en listas restrictivas, listas OFAC, PEPS,Clinton y ONU, en aras de prevenir el lavado de activos y financiación del terrorismo. Por otro lado, el titular autoriza el envío de contenido publicitario a través de medios físicos y electrónicos, incluyendo pero sin limitar, lo siguientes medios: correo electrónico, , Short Message Servive (SMS por sus siglas en inglés), Multimedia Message Service (MMS por sus siglas en inglés), Unstructured Supplementary Service Data (USSD por sus siglas en inglés), mensajes de WhatsApp, Push Notification dentro de la franja horaria entre las 8:00 am hasta las 9:30 pm, y los días sábado, domingo y festivo hasta las 18:30 horas de manera segmentada.</p>
        </div>
        <div class="cell_item">
          <h2>TRATAMIENTO DE DATOS PERSONALES EN LAS PÁGINAS WEB</h2>
          <p>La Compañía recolecta datos personales privados de terceros a través de su página web o redes sociales y los almacena en una base de datos la cual es calificada por la compañía como de reserva, y solo será revelada con la expresa autorización del titular o cuando una Autoridad Competente lo solicite.Las finalidades para las cuales son utilizados los datos personales que son recopilados a través de la página web La Compañía, serán: Permitir la comunicación con los clientes, prospectos o terceros mediante la sección de contáctenos, suscríbete o a través del canal de sugerencias. Prestar servicios crediticios en línea.</p>
          <p>Dar a conocer el portafolio de servicios crediticios que ofrece la Empresa.</p>
          <p>Publicar eventos, reconocimientos, certificaciones y noticias de interés.</p>
          <p>Recepcionar y dar trámite a las quejas y reclamos de los interesados.</p>
          <p>Fines legales, contables, administrativos, comerciales, promocionales, informativos, de mercadeo y ventas.</p>
          <p>Realizar campañas de promoción, marketing, publicidad.</p>
          <p>En todo caso, la información no será objeto de tratamiento por un período superior al pactado con el tercero a través de la autorización para usar sus datos personales, y de acuerdo con las circunstancias legales o contractuales que hacen necesario el manejo de la información.</p>
        </div>
        <div class="cell_item">
          <h2>SEGURIDAD EN INTERNET</h2>
          <p>Para MiRuiqueza S.A.S la privacidad y la seguridad de los datos de sus clientes y usuarios es fundamental y estamos conscientes de la confianza que depositan en nosotros al requerir nuestros servicios. Por eso, tomamos todas las medidas de seguridad a nuestro alcance para proteger sus datos. Todas las plataformas que procesan tarjetas de pago deben contar con el Estándar de Seguridad de Datos de la Industria de las Tarjetas de Pago (PCI DSS, Payment Card Industry Data Security Standard, en sus siglas en inglés. PCI DSS es un estándar que establece un conjunto de medidas, prácticas y herramientas que pretenden garantizar la seguridad en el tratamiento de la información asociada a medios de pago electrónicos. Aunque nuestro sistema no procesa tarjetas de pago nuestros servidores están alineados con los controles de la Norma ISO 270012:12013 para garantizar la seguridad de la información.</p>
          <p>La Compañía usa cookies, una herramienta empleada por los sitios web para almacenar y recuperar información acerca de sus visitantes. En nuestro caso, las cookies solo recuperan información que esté disponible en nuestra página. Además, la información que se recoge nos ayuda a mejorar la experiencia del usuario en nuestro sitio.</p>
          <p>Nuestro sitio cuenta con certificado SSL (Secure Sockets Layer, en sus siglas en inglés) el cual nos permite encriptar los datos mientras viajan desde el computador de nuestros clientes o usuarios hasta nuestro servidor, de esta forma nadie podrá interceptar la comunicación para apoderarse de sus datos personales.</p>
          <p>No obstante, lo anterior, es una responsabilidad de nuestros clientes o usuarios implementar los controles de seguridad necesarios, en sus equipos y redes privadas para su navegación hacia el/los portales de la Compañía o para el envío de correos electrónicos. De esta manera MiRuiqueza S.A.S no se responsabiliza por cualquier consecuencia derivada del ingreso fraudulento por parte de terceros a la base de datos y por alguna falla técnica en el funcionamiento que sobrepasen las actividades desarrolladas con la diligencia debida.</p>
        </div>
        <div class="cell_item">
          <h2>TRANSFERENCIA Y TRANSMISIÓN INTERNACIONAL DE DATOS</h2>
          <p>Actualmente la Compañía realiza Transferencia Internacional de bases de datos relacionada con información de deudores o cartera a países como Estados Unidos, lo anterior con la única finalidad de celebrar contratos de participación o venta de activos; lo cual a su vez implica la transferencia internacional de las bases de datos con información personal de la Compañía.</p>
          <p>Para realizar dicho proceso, MiRuiqueza S.A.S ha verificado que dicho país reúna los estándares que garantizan un nivel adecuado de protección de datos personales, encontrando que en efecto el numeral</p>
          <p>3.2 del Capítulo tercero, del Título V de la Circular Única 005 del 10 de agosto de 2017 de la Superintendencia de Industria y Comercio incluye a dicho país en la lista de países seguros. Adicionalmente, la Compañía puede llegar a realizar Transmisión</p>
          <p>internacional de datos personales, de    anterior, con la finalidad de gestionar todos los procesos internos de la Compañía. En todo caso, además de contar con la autorización expresa e inequívoca por parte del Titular, MiRuiqueza S.A.S se asegurará que la acción proporciona los niveles adecuados de protección de datos y atiende a los requisitos fijados en Mexico por el Régimen de Habeas Data.</p>
        </div>
        <div class="cell_item">
          <h2>PROCEDIMIENTO O PROTOCOLO PARA LA ATENCIÓN DE CONSULTAS, RECLAMOS Y PETICIONES, ASÍ COMO LOS MECANISMOS PARA EJERCER LOS DERECHOS DE LOS TITULARES</h2>
          <p>El Titular, sus causahabientes, su representante y/o apoderado, o quien se determine por estipulación a favor de otro; podrá hacer ejercicio de sus derechos contactándose con nosotros a través de comunicación escrita dirigida al área encargada de la protección de datos personales en la Compañía, ÁREA JURÍDICA con el apoyo técnico de sus proveedores legales expertos en Protección de datos, MUVA LEGAL. La comunicación podrá ser enviada al siguiente correo electrónico: juridicadragonflytechsas@gmail.com</p>
        </div>
        <div class="cell_item">
          <h2>CONSULTAS</h2>
          <p>Se podrá consultar la información personal del Titular que repose en las bases de datos de MiRuiqueza</p>
          <p>S.A.S   y la Compañía se encargará de suministrar toda la información contenida en el registro individual o que esté vinculada con la identificación del solicitante. La consulta una vez recibida por la Compañía, será atendida en un término máximo de 10 días hábiles contados a partir de la fecha de recibo de la misma. Cuando no fuere posible atender la consulta dentro de dicho término, se informará al interesado, expresando los motivos de la demora y señalando la nueva fecha en que se atenderá tal consulta, la cual en ningún caso podrá superar los cinco (5) días hábiles siguientes al vencimiento del primer término.</p>
        </div>
        <div class="cell_item">
          <h2>RECLAMOS</h2>
          <p>Cuando se considere que la información contenida en una base de datos de la Compañía debe ser objeto de corrección, actualización o supresión, o cuando se advierta el presunto incumplimiento de cualquiera de los deberes contenidos en la Ley de Habeas Data, se podrán presentar reclamación ante la Compañía la cual será tramitada bajo las siguientes reglas:</p>
          <p>El reclamo se formulará mediante comunicación escrita dirigida a MiRuiqueza S.A.S con la identificación del Titular, la descripción de los hechos que dan lugar al reclamo, la dirección, y acompañando los documentos que se quiera hacer valer.</p>
          <p>Si el reclamo resulta incompleto, se requerirá al interesado dentro de los cinco (5) días siguientes a la recepción del reclamo para que subsane las fallas. Transcurridos dos (2) meses desde la fecha del requerimiento, sin que el solicitante presente la información requerida, se entenderá que ha desistido del reclamo.</p>
          <p>En caso de que la Compañía reciba un Reclamo del cual no sea competente para resolverlo, la Empresa dará traslado a quien efectivamente corresponda en un término máximo de dos (2) días hábiles e informará al Titular. Los canales de atención </p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;● service@moaimagic.net</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;● Línea de atención:318-717-1958</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;● Oficina principal Cra 13 # 97 – 51 OFI 201</p>
          <p>Una vez recibido el reclamo completo, la Compañía incluirá en la respectiva base de datos una leyenda que diga «reclamo en trámite» y el motivo del mismo, en un término no mayor a dos (2) días hábiles. La Compañía conservará dicha leyenda en el dato objeto de discusión hasta que el reclamo sea decidido.</p>
          <p>El término máximo para atender el reclamo será de quince (15) días hábiles contados a partir del día siguiente a la fecha de su recibo. Cuando no fuere posible atender el reclamo dentro de dicho término, la Compañía informará al Titular los motivos de la demora y la nueva fecha en que se atenderá su reclamo, la cual en ningún caso podrá superar los ocho (8) días hábiles siguientes al vencimiento del primer término.</p>
        </div>
        <div class="cell_item">
          <h2>CONTENIDO MÍNIMO DE LA SOLICITUD</h2>
          <p>Las solicitudes que presente el titular con el fin de realizar una consulta o reclamo sobre el uso y manejo de sus datos personales deberán contener unas especificaciones mínimas, en aras de brindar al Titular una respuesta clara y coherente con lo solicitado. Los requisitos de la solicitud son:</p>
          <p>Estar dirigida a MiRuiqueza S.A.S</p>
          <p>Contener la identificación del Titular (Nombre y Documento de identificación).</p>
          <p>Contener la descripción de los hechos que motivan la consulta o el reclamo.</p>
          <p>El objeto de la petición.</p>
          <p>Indicar la dirección de notificación del Titular, física y/o electrónica (e-mail).</p>
          <p>Anexar los documentos que se quieren hacer valer. (Especialmente para reclamos)
            En el evento en que la consulta o reclamo sea presentado presencialmente el Titular deberá plasmar su solicitud o reclamo por escrito sin ninguna formalidad más que los requisitos exigidos en el punto anterior.</p>
        </div>
        <div class="cell_item">
          <h2>REQUISITO DE PROCEDIBILIDAD</h2>
          <p>El Titular, sus causahabientes, su representante y/o apoderado, o quien se determine por estipulación a favor de otro; sólo podrá presentar queja ante la Superintendencia de Industria y Comercio por el ejercicio de sus derechos una vez haya agotado el trámite de Consulta o Reclamo directamente ante la Compañía.</p>
        </div>
        <div class="cell_item">
          <h2>PETICIÓN DE ACTUALIZACIÓN Y/O RECTIFICACIÓN</h2>
          <p>MiRuiqueza S.A.S rectificará y actualizará, a solicitud del titular, la información que sea inexacta o se encuentre incompleta, atendiendo al procedimiento y los términos antes señalados, para lo cual el Titular deberá allegar la solicitud según los canales dispuestos por la compañía, indicando la actualización y rectificación del dato y a su vez deberá aportar la documentación que soporte tal petición.</p>
        </div>
        <div class="cell_item">
          <h2>REVOCATORIA DE LA AUTORIZACIÓN Y/O SUPRESIÓN DEL DATO</h2>
          <p>El Titular podrá revocar en cualquier momento el consentimiento o autorización dada para el tratamiento de sus datos personales, siempre y cuando no se encuentre un impedimento consagrado en una disposición legal o contractual. Así también el Titular tiene derecho a solicitar en todo momento a la Compañía la supresión o eliminación de sus datos personales cuando:</p>
          <p>Considere que los mismos no están siendo tratados conforme a los principios, deberes y obligaciones previstas en la normatividad vigente.</p>
          <p>Hayan dejado de ser necesarios o pertinentes para la finalidad para la cual fueron obtenidos.</p>
          <p>Se haya cumplido el tiempo necesario para el cumplimiento de los fines para los que fueron obtenidos.</p>
          <p>Tal supresión implica la eliminación bien sea de manera total o parcial de la información personal, de acuerdo con lo solicitado por el titular en los registros, archivos, bases de datos o tratamientos realizados por la Compañía. El derecho de cancelación no es absoluto y por lo tanto la Compañía podrá negar revocatoria de autorización o eliminación de los datos personales en los siguientes casos:</p>
          <p>El titular tenga un deber legal o contractual de permanecer en la base de datos.</p>
          <p>La eliminación de datos obstaculice actuaciones judiciales o administrativas vinculadas a obligaciones fiscales, la investigación y persecución de delitos o la actualización de sanciones administrativas.</p>
          <p>Los datos sean necesarios para proteger los intereses jurídicamente tutelados del titular; para realizar una acción en función del interés público, o para cumplir con una obligación legalmente adquirida por el titular.</p>
        </div>
        <div class="cell_item">
          <h2>MODIFICACIÓN DE LAS POLÍTICAS</h2>
          <p>La Compañía se reserva el derecho de modificar la Política de Tratamiento y Protección de Datos Personales en cualquier momento. Sin embargo, toda modificación será comunicada de forma oportuna a los titulares de los datos personales a través de los medios habituales de contacto con cinco (5) días hábiles de antelación a su entrada en vigor. En el evento que un titular no esté de acuerdo con la nueva Política General o especial y con razones válidas que se constituyan en una justa causa para no continuar con la autorización para el tratamiento de datos personales, el Titular podrá solicitar a la Compañía el retiro de su información a través de los canales indicados en el Capítulo 12. Sin embargo, los Titulares no podrán solicitar el retiro de sus datos personales cuando la Compañía tenga un deber legal o contractual de tratar los datos.</p>
        </div>
        <div class="cell_item">
          <h2>LEY Y JURISDICCIÓN</h2>
          <p>Toda interpretación, actuación judicial o administrativa derivada del tratamiento de los datos personales que conforman las bases de datos de MiRuiqueza S.A.S y la presente Política, estará sujeta a las normas de protección personal establecidas en la República de Mexico y las autoridades administrativas o jurisdiccionales competentes para la resolución de cualquier inquietud, queja o demanda sobre las mismas serán las de la República de Mexico.</p>
        </div>
        <div class="cell_item">
          <h2>VIGENCIA</h2>
          <p>La presente Política rige a partir del 030/09/2022.</p>
          <p>ANEXOS Ninguno.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.loan_item{
  padding-bottom: 60px;
  .inner{
    min-width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    h3{
      font-size: 18px;
      color: white;
      padding: 20px calc((100% - 1100px) / 2) !important;
      background: linear-gradient(284deg, #43C1D8 0%, #4486EC 100%);
    }
    .left_cell{
      padding: 0px calc((100% - 1100px) / 2 ) !important;
      .cell_item{
        h2{
          font-size: 16px;
          margin: 30px 0;
        }
        p{
          font-size: 14px;
          color: #999999;
          line-height: 25px;
          b{
            color: black !important;
          }
        }
      }
    }
    .right_cell{
      float: left;
      width: 50%;
      // padding: 80px 0;
      img{
        width: 522px;
      }
    }
  }

}
@media only screen and (max-width: 520px){
  .loan_item{
    width: calc(100vw) !important;
    .inner{
      min-width: calc(100vw) !important;
      h3{
        font-size: 16px !important;
        padding: 20px !important;
      }
      .left_cell{
        padding:  0 20px !important;
        .cell_item{
          h2{
            font-size: 14px !important;
          }
          p{
            font-size: 12px !important;
          }
        }
      }
    }
  }
}
</style>
